* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: #444;
    background: #fff;
    height: 100vh;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.inner {
    width: 100%;
    padding: 8rem 10px 4rem;
}

.form-container {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    border-radius: calc(0.5 * 100px);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
    justify-content: space-evenly;
}

.input-text {
    font-size: 1rem;
    font-weight: 400;
    width: 85%;
    padding-right: 5px;
    padding-left: 10px;
    border-radius: calc(0.5 * 100px);
}

.input-text::placeholder {
    color: #000;
}

.input-submit {
    background: transparent;
    color: #5b5b5b;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
}

.input-text,
.input-submit {
    height: 45px;
    outline: none;
    border: none;
}

.dropdown-container {
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
}

.dropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.dropdown-menu {
    position:absolute;
    transform: translateY(4px);
    width:100%;
    border: 1px solid #ccc;
    border-radius:5px;
    overflow:auto;
    max-height: 150px;
}

.dropdown-item {
    padding: 5px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: rgb(0, 0, 0);
}

.dropdown-item.selected {
    background-color: #0d6efd;
    color: rgb(0, 0, 0);
}
.dropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.dropdown-tag-item {
    background-color: rgb(0, 0, 0);
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.dropdown-tag-close {
    display: flex;
    align-items: center;
}

.search-box {
    padding: 5px;
    background-color: #eee;
}

.search-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
